import Script from 'next/script'
import { useContext } from 'react'
import { ChatContext } from '../providers/ChatContext'

declare global {
  interface Window {
    smartsupp: any
  }
}

const Smartsupp = () => {
  const { dispatch } = useContext(ChatContext)

  return (
    <>
      <Script
        src="/chat/smartsupp_key.js"
        strategy="beforeInteractive"
        type="text/javascript"
      />

      <Script
        src="https://www.smartsuppchat.com/loader.js?"
        strategy="afterInteractive"
        type="text/javascript"
        onLoad={() => {
          dispatch({ type: 'SET_SDK', payload: () => window.smartsupp })
          dispatch({
            type: 'SET_LOCALE',
            payload: navigator.language.split('-')[0],
          })
        }}
      />
    </>
  )
}

export default Smartsupp
